<template>
  <div>
    <div class="back-img">
      <div style="font-size: 50px; color: #fff">PROGRAMME</div>
    </div>
      <el-row>
          <el-col :span="12">
              <div class="program">
                  <img class="image1" :src="require('assets/images/programme/program1.png')" alt="" />
              </div>
          </el-col>
          <el-col :span="12">
              <div class="program">
                  <img  class="image1" :src="require('assets/images/programme/program2.png')" alt=""/>
              </div>
          </el-col>
      </el-row>

    <div class="program"> 
      <a>Participant Survey: All the participants are invited to fill in the questionnaire after the conference by scanning the QR code. </a>
      <div> 
     </div>
       <img :src="require('assets/images/programme/qrcode.png')" alt="" />
    </div>

  </div>

</template>

<script>
export default {
  name: "Programme"
}
</script>

<style scoped>
.back-img{
  background-image: url("../assets/images/callfor/bgc1.png");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.program-zone{
  margin: 100px 200px;
  font-size: 18px;
  
}
.program {
    text-align: center;
    padding: 20px;
}

.image1 {
    width: 650px;
}
a{
      margin: 50px 20px;
      color:#222222;
      text-decoration: none;
      font-weight: 800;    
}

</style>
